<template>
    <div class="container">
        <div v-if="messageAlert">
          <Notification :message="messageAlert" @statusAlertMsg="cambiarMsgAlert" />
        </div>
        <div class="row justify-content-center">
            <div class="col-md-12">
              <dx-data-grid
                id="gridContainer"
                :dataSource="dataSource"
                :allowColumnReordering="true"
                :hoverStateEnabled="true"
                :showColumnLines="true"
                :showRowLines="true"
                :showBorders="true"
                :rowAlternationEnabled="true"
                :selectedRowKeys="[]"
                :height="600"
                :columnAutoWidth="true"
                :allowColumnResizing="true"  
              >
              <dx-toolbar>
                <dx-item
                  location="after"
                  template="refreshTemplate"
                ></dx-item>
                <dx-item
                  location="after"
                  name="exportButton"
                ></dx-item>
                <dx-item
                  location="after"
                  name="columnChooserButton"
                ></dx-item> 
              </dx-toolbar>
              <template #refreshTemplate>
                <dx-button
                  icon="plus"
                  @click="createUser()"
                ></dx-button>
              </template>
                <dx-column-chooser :enabled="true"></dx-column-chooser>
                <dx-export :enabled="true"></dx-export>
                <dx-scrolling row-rendering-mode="virtual"></dx-scrolling>
                <dx-paging :page-size="10"></dx-paging>
                <dx-pager
                  :visible="true"
                  :allowed-page-sizes="pageSizes"
                  :display-mode="displayMode"
                  :show-page-size-selector="showPageSizeSelector"
                  :show-info="showInfo"
                  :show-navigation-buttons="showNavButtons"
                ></dx-pager>  
                <dx-header-filter :visible="true"></dx-header-filter>
                <dx-filter-row :visible="true"></dx-filter-row>
                <dx-column-fixing :enabled="true"></dx-column-fixing>
                <dx-column-chooser
                  :enabled="true"
                  mode="select"
                  :allowSearch="true"
                >
                </dx-column-chooser>
                <dx-selection mode="single"></dx-selection>
                <dx-state-storing 
                  :enabled="false"
                  type="localStorage"
                  storageKey="storage"
                >
                </dx-state-storing>
                <dx-column
                  dataField="id"
                  caption=""
                  :showInColumnChooser="false"
                  :width="50"
                  :allowFiltering="false"
                  :allowSorting="false"
                  cellTemplate="cellEdit"
                  :fixed="true">
                </dx-column>
                <dx-column 
                  dataField="name"
                  :width="200"
                  :minWidth="80"
                  :fixed="true"
                  caption="Nombre">
                </dx-column>
                <dx-column 
                  dataField="email"
                  :width="200"
                  :minWidth="80"
                  :fixed="true"
                  caption="Correo">
                </dx-column>
                <dx-column 
                  dataField="roles[0].name"
                  :width="200"
                  :minWidth="80"
                  :fixed="true"
                  caption="Rol">
                </dx-column>
                <dx-column 
                  dataField="estatus"
                  :width="80"
                  :minWidth="80"
                  :fixed="true"
                  caption="Estatus">
                </dx-column>
                <dx-group-panel :visible="true"></dx-group-panel>
                <template #cellEdit="{ data }"> 
                  <div style="text-align: center;">
                    <a href="#" class="fas fa-pen fa-sm" @click="onEditUser(data)" title="Editar" style="text-decoration:none;"></a>
                    <a href="#" :class="data.data.status==0?'mx-1 fas fa-lock fa-sm':'mx-1 fas fa-unlock fa-sm'" @click="onStatusUser(data)" :title="data.data.status==0?'Activar':'Desactivar'" style="text-decoration:none;"></a>
                    <a href="#" class="fas fa-trash fa-sm" @click="onDeleteUser(data)" title="Eliminar" style="text-decoration:none;"></a>
                  </div>
                </template>
              </dx-data-grid>              
            </div>
        </div>
        <dx-popup
          :width="'480px'"
          :height="'auto'"
          :showTitle="true"
          titleTemplate="titleModal"
          :dragEnabled="false"
          :closeOnOutsideClick="true"
          :visible="popupVisibleCreate"
          @hidden="onHidden"
        >
          <template #titleModal>
            <h3>{{titleModal}}</h3>
          </template>
          <div>
            <div class="row">
              <div class="col-md-12">
                <form action="">
                  <div class="form">
                    <div class="dx-fieldset">
                      <div class="dx-field">
                        <div class="dx-field-label">Nombre:</div>
                        <div class="dx-field-value">
                          <dx-text-box v-model="user.name"></dx-text-box>
                          <small style="color:#FF6868;" v-for="(e_name, index) in errors.name" :key="index">{{e_name}}</small>
                        </div>
                      </div>
                      <div class="dx-field">
                        <div class="dx-field-label">Correo electrónico</div>
                        <div class="dx-field-value">
                          <dx-text-box v-model="user.email"></dx-text-box>
                          <small style="color:#FF6868;" v-for="(e_email, index) in errors.email" :key="index">{{e_email}}</small>
                        </div>
                      </div>
                      <div class="dx-field">
                        <div class="dx-field-label">Contraseña</div>
                          <div class="dx-field-value">
                            <input class="form-control" type="password" name="" id="ePass" v-model="user.password"  v-on:keyup="inputKeydown">
                            <small style="color:#FF6868;" v-for="(e_password, index) in errors.password" :key="index">{{e_password}}</small>
                        </div>
                      </div>
                       <div class="password-instructions">
                          <span class="small">Para definir tu contraseña, considera una longitud de 8 a 12 caracteres y utilizar al menos: </span>
                          <div class="row">
                              <div class="col-6">
                                  <div id="password_mayus" >
                                     <i class="desactive_password fas fa-times" ref="password_mayus"></i>
                                      <span class="small">&nbsp;Una letra mayúscula</span>
                                  </div>
                                  <div id="password_minus">
                                      <i class="desactive_password fas fa-times" ref="password_minus"></i>
                                       <span class="small">&nbsp;Una letra minúscula</span>
                                  </div>
                              </div>
                              <div class="col-6">
                                  <div id="password_numer">
                                      <i class="desactive_password fas fa-times" ref="password_numer"></i>
                                      <span class="small">&nbsp;Un número</span>
                                  </div>
                                  <div id="password_carac">
                                      <i class="desactive_password fas fa-times" ref="password_carac"></i>
                                      <span class="small">&nbsp;Un carácter especial</span>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <br>
                      <div class="dx-field">
                        <div class="dx-field-label">Confirmar contraseña</div>
                          <div class="dx-field-value">
                            <dx-text-box mode="password" v-model="user.cpassword"></dx-text-box>
                            <small style="color:#FF6868;" v-for="(e_cpassword, index) in errors.cpassword" :key="index">{{e_cpassword}}</small>
                        </div>
                      </div>
                      <div class="dx-field">
                        <div class="dx-field-label">Rol:</div>
                        <div class="dx-field-value">
                            <dx-select-box
                                :search-enabled="true"
                                :data-source="roles"
                                :search-mode="searchModeOption"
                                :search-expr="searchExprOption"
                                :search-timeout="searchTimeoutOption"
                                :min-search-length="minSearchLengthOption"
                                :show-data-before-search="showDataBeforeSearchOption"
                                display-expr="name"
                                value-expr="id"
                                v-model="user.rol"
                            ></dx-select-box>
                            <small style="color:#FF6868;" v-for="(e_rol, index) in errors.rol" :key="index">{{e_rol}}</small>
                        </div>
                      </div>
                      <div  v-if="option=='edit'" class="dx-field" >
                        <div class="dx-field-label">Estatus:</div>
                        <div class="dx-field-value">
                          <div v-if="user.status==1" class="dx-field-label">Activo</div>
                          <div v-if="user.status==0" class="dx-field-label">Inactivo</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="row">
              <div class="modal-footer">
                <dx-button
                  type="normal"
                  text="Cancelar"
                 @click="cancelUser()"
                ></dx-button>
                <dx-button
                  v-if="option=='save'"
                  icon="save"
                  type="default"
                  text="Crear"
                  @click="newUser()"
                ></dx-button>
                <dx-button
                  v-if="option=='edit' && user.status==1"
                  icon="clear"
                  type="danger"
                  text="Inactivar"
                  @click="openModalStatus()"
                ></dx-button>
                <dx-button
                  v-if="option=='edit' && user.status==0"
                  icon="fas fa-unlock fa-sm"
                  type="default"
                  text="Activar"
                  @click="openModalStatus()"
                ></dx-button>
                <dx-button
                  v-if="option=='edit'"
                  icon="fas fa-lock fa-sm"
                  type="default"
                  text="Modificar"
                  @click="updateUser()"
                ></dx-button>
              </div>
            </div>
          </div>
        </dx-popup>
        <dx-popup
          :width="'auto'"
          :height="'auto'"
          :showTitle="true"
          titleTemplate="titleModal"
          :dragEnabled="false"
          :closeOnOutsideClick="true"
          :visible="popupVisibleDelete"
          @hidden="onHiddenDelete"
        >
          <template #titleModal>
            <h3>Eliminar usuario</h3>
          </template>
          <div>
            <div class="row">
              <div class="col-md-12">
                <h3>¿Deseas eliminar al usuario "{{user_name}}"?</h3>
                <br>
              </div>
            </div>
            <div class="row">
              <div class="modal-footer">
                <dx-button
                  type="normal"
                  text="Cancelar"
                 @click="cancelUserDelete()"
                ></dx-button>
                <dx-button
                  icon="trash"
                  type="danger"
                  text="Eliminar"
                  @click="deleteUser()"
                ></dx-button>
              </div>
            </div>
          </div>
        </dx-popup>
        <dx-popup
          :width="'auto'"
          :height="'auto'"
          :showTitle="true"
          titleTemplate="titleModal"
          :dragEnabled="false"
          :closeOnOutsideClick="true"
          :visible="popupVisibleStatus"
          @hidden="onHiddenStatus"
        >
          <template #titleModal>
            <h3>{{titleStatus}} usuario</h3>
          </template>
          <div>
            <div class="row">
              <div class="col-md-12">
                <h3>¿Deseas {{titleStatus}} al usuario "{{user_name}}"?</h3>
                <br>
              </div>
            </div>
            <div class="row">
              <div class="modal-footer">
                <dx-button
                  type="normal"
                  text="Cancelar"
                 @click="cancelUserStatus()"
                ></dx-button>
                <dx-button
                  type="danger"
                  :text="user_status==1?'Desactivar':'Activar'"
                  @click="chageStatus()"
                ></dx-button>
              </div>
            </div>
          </div>
        </dx-popup>
    </div>
</template>
<script>

import "bootstrap/dist/css/bootstrap.min.css";
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxLookup,
  DxForm,
  DxScrolling,
  DxPager,
  DxExport,
  DxHeaderFilter,
  DxFilterRow,
  DxColumnFixing,
  DxColumnChooser,
  DxSelection,
  DxStateStoring,
  DxGroupPanel,
  DxToolbar,
} from 'devextreme-vue/data-grid';
import {
    DxValidator,
    DxRequiredRule,
    DxCustomRule
} from 'devextreme-vue/validator';
 
import { DxTextArea } from 'devextreme-vue/text-area';
import { DxTextBox } from 'devextreme-vue/text-box';
import { DxItem } from 'devextreme-vue/form';
import { DxPopup } from 'devextreme-vue/popup';
import { DxButton } from 'devextreme-vue/button';
import { DxCheckBox } from 'devextreme-vue';
import { DxSelectBox } from 'devextreme-vue/select-box';
import { DxLoadPanel } from 'devextreme-vue/load-panel';
import Notification from "../NotificationComponent";

export default {
    components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxLookup,
    DxForm,
    DxItem,
    DxTextArea,
    DxScrolling,
    DxPager,
    DxExport,
    DxHeaderFilter,
    DxFilterRow,
    DxColumnFixing,
    DxColumnChooser,
    DxSelection,
    DxStateStoring,
    DxGroupPanel,
    DxPopup,
    DxButton,
    DxTextBox,
    DxToolbar,
    DxValidator,
    DxRequiredRule,
    DxCustomRule,
    DxCheckBox,
    DxSelectBox,
    DxLoadPanel,
    Notification,
  },
  data() {
    return {
        roles:[],
        dataSource:[],
        displayModes: [{ text: 'Display Mode \'full\'', value: 'full' }, { text: 'Display Mode \'compact\'', value: 'compact' }],
        displayMode: 'full',
        pageSizes: [5, 10, 'all'],
        showPageSizeSelector: true,
        showInfo: true,
        showNavButtons: true,
        errors:[],
        user_id:"",
        user_name:"",
        user_status:1,
        titleStatus:"Activar",
        titleModal:"",
        option:"save",
        user:{
            id:"",
            name:"",
            email:"",
            password:null,
            cpassword:null,
            rol:"",
            status:"",
        },
        popupVisibleCreate: false,
        popupVisibleDelete:false,
        popupVisibleStatus:false,
        titleStatus:"Activar",
        searchModeOption: 'contains',
        searchExprOption: 'name',
        searchTimeoutOption: 200,
        minSearchLengthOption: 0,
        showDataBeforeSearchOption: false,
        isLoadPanelVisible: false,
        messageAlert:0,
    }
  },
  methods: {
    cambiarMsgAlert(value){
      this.messageAlert= value;
    },
    inputKeydown(e){
      const pass = e.key;
      let mayus = new RegExp("^(?=.*[A-Z])");
      let minus = new RegExp("^(?=.*[a-z])");
      let num = new RegExp("^(?=.*[0-9])");
      let special = new RegExp("^(?=.*[-.!%&@#$^*?_~;,:|=¡¿+<>])");
      let upper = this.$refs.password_mayus;
      let lower = this.$refs.password_minus;
      let number = this.$refs.password_numer;
      let specialChar = this.$refs.password_carac; 
      const regExp = [ mayus, minus, num, special ];
      const elements = [ upper, lower, number, specialChar];
      for (let i = 0; i < 4; i++) {                      
          elements[i].classList.remove('active_password','fa-check');
          elements[i].classList.add('desactive_password','fa-times');  
      }    
      let cadena = document.getElementById("ePass").value;
      for (let x = 0; x< cadena.length; x++) {
          let caracter = cadena.charAt(x);
          for (let i = 0; i < 4; i++) {
              if (pass && regExp[i].test(caracter)) {
                  elements[i].classList.remove('desactive_password', 'fa-times');
                  elements[i].classList.add('active_password', 'fa-check');  
              }            
          }
      }   
    },   
    createUser(){
      this.cleanTextBox();
      this.showChildModal();
      this.titleModal="Nuevo usuario"
      this.option="save";
    },
    cleanTextBox(){
      this.user.id="";
      this.user.name="";
      this.user.email="";
      this.user.password=null;
      this.user.cpassword=null;
      this.user.rol="";
      this.user.status="";
    },
    showChildModal(){
      this.popupVisibleCreate =  true;
    },
    hideChildModal(){
      this.popupVisibleCreate =  false;
    },
    onEditUser(value){
      this.titleModal="Editar usuario"
      this.option="edit";
      this.cleanTextBox();
      this.showChildModal();
      this.user_id = value.value;
      this.user.id = value.value;
      this.user.name=value.data.name;
      this.user.email=value.data.email;
      if(value.data.roles.length>0)
        this.user.rol= value.data.roles[0].id;
      this.user.status = value.data.status;
    },
    onStatusUser(value){
      this.popupVisibleStatus=true;
      this.user_status = value.data.status;
      this.titleStatus =  value.data.status==1?'Desactivar':'Activar';
      this.user_id = value.value;
      this.user_name = value.data.name;
    },
    onDeleteUser(value){
      this.popupVisibleDelete=true;
      this.user_id = value.value;
      this.user_name = value.data.name;
    },
    cancelUser(){
      this.cleanTextBox();
      this.hideChildModal();
      this.errors=[];
    },
    newUser(){
      axios.post('usuarios', this.user).then(response => {
        if(response && response.data.statusCode == 200){
          this.messageAlert = response.data.message;
          this.cleanTextBox();
          this.hideChildModal();
          this.getList();
				}
				else{
					console.log(response);
				}
			}).catch((error)=> {
				if (error.response.status == 422){
					this.errors = error.response.data.errors;
				}else{
					console.log(this.errors);
				}
			}); 
    },
    openModalStatus(){
      this.popupVisibleCreate =  false;
      this.popupVisibleStatus=true;
      this.user_status = this.user.status;
      this.titleStatus =  this.user.status==1?'Desactivar':'Activar';
      this.user_id = this.user.id;
      this.user_name = this.user.name;
    },
    updateUser(){
      if(this.user.password==""&&this.user.cpassword==""){
        this.user.password=null;
        this.user.cpassword=null;
      }
      axios.put('usuarios/'+this.user_id, this.user).then(response => {
        if(response && response.data.statusCode == 200){
          this.messageAlert = response.data.message;
          this.cleanTextBox();
          this.hideChildModal();
          this.getList();
				}
				else{
					console.log(response);
				}
			}).catch((error)=> {
				if (error.response.status == 422){
					this.errors = error.response.data.errors;
				}else{
					console.log(this.errors);
				}
			}); 
    },
    cancelUserDelete(){
      this.popupVisibleDelete=false;
      this.subdomain_id = "";
      this.subdomain_name = "";
    },
    deleteUser(){
      axios.delete('usuarios/'+this.user_id).then(response => {
        if(response && response.data.statusCode == 200){
          this.messageAlert = response.data.message;
          this.cancelUserDelete();
          this.getList();
				}
				else{
					console.log(response);
				}
			}).catch((error)=> {
				if (error.response.status == 422){
					console.log(error);	
				}else{
					console.log(this.errors);
				}
			}); 
    },
    cancelUserStatus(){
      this.popupVisibleStatus=false;
      this.user_id = "";
      this.user_name = "";
    },
    onHidden(e){
      this.cancelUser();
    },
    onHiddenDelete(e){
      this.cancelUserDelete();
    },
    onHiddenStatus(e){
      this.cancelUserStatus;
    },
    getList(){
	    axios.get('listusers').then(response=>{
        this.dataSource = response.data.users;
        this.roles = response.data.roles;
      })
    },
    chageStatus(){
      axios.post('banned-user/'+this.user_id).then(response=>{
        this.messageAlert = response.data.message;
        this.popupVisibleStatus=false;
        this.cleanTextBox();
        this.getList();
      });
    }
  },
  mounted(){
    this.getList();
  },
}
</script>
<style scoped>
  .small {
    font-size: smaller;
  }
  .active_password{
      font-size: smaller;
      color: #33FFA9;
  }
  .desactive_password{
    font-size: smaller;
    color: #C4C4C4;
    transition: all 0.3s;
  }
</style>