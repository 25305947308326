<template>
    <div class="container d-flex justify-content-center">
        <div class="card me-2" style="width: 35vw;">
            <div class="card-header">Subdominios</div>
            <div class="card-body">
                <div class="container">
                    <small style="color:#FF6868;" v-for="(e_subdomain, index) in errors.subdomain" :key="index">{{e_subdomain}}</small>
                    <div class="row my-2">
                        <div class="col">
                            <label class="fw-bold">
                                <input type="checkbox" v-model="subdomainAll" @change="selectedAll()"> Seleccionar todo
                            </label>
                        </div>
                    </div>
                    <div class="row" v-for="subdomain in subdomains" :key="subdomain.id">
                        <div class="col">
                            <label>
                                <input type="checkbox" v-model="subdomain.selected"> {{subdomain.url}}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card" style="width: 65vw;">
            <h5 class="card-header">Crear/Editar usuario en plataformas CAPI</h5>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-12">
                        <form action="">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-floating mb-2">
                                        <input class="form-control" type="text" name="name" id="name" placeholder="Nombre" v-model="user.name">
                                        <label for="name">Nombre</label>
                                    </div>
                                    <small style="color:#FF6868;" v-for="(e_name, index) in errors.name" :key="index">{{e_name}}</small>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-floating mb-2">
                                        <input class="form-control" type="text" name="last_name" id="last_name" placeholder="Apellidos" v-model="user.last_name">
                                        <label for="last_name">Apellidos</label>
                                    </div>
                                    <small style="color:#FF6868;" v-for="(e_last_name, index) in errors.last_name" :key="index">{{e_last_name}}</small>
                                </div>
                            </div>
                            <div class="form-floating mb-2">
                                <input class="form-control" type="email" name="email" id="email" placeholder="Correo electrónico" v-model="user.email">
                                <label for="email">Correo electrónico</label>
                            </div>
                            <small style="color:#FF6868;" v-for="(e_email, index) in errors.email" :key="index">{{e_email}}</small>
                            <div class="form-floating mb-2">
                                <input class="form-control" type="password" name="password" id="ePass" placeholder="Contraseña" v-model="user.password" v-on:keyup="inputKeydown">
                                <label for="password">Contraseña</label>
                            </div>
                            <small style="color:#FF6868;" v-for="(e_password, index) in errors.password" :key="index">{{e_password}}</small>    
                            <div class="password-instructions mb-2">
                                <span class="small">Para definir tu contraseña, considera una longitud de 8 a 12 caracteres y utilizar al menos: </span>
                                <div class="row">
                                    <div class="col-6">
                                        <div id="password_mayus" >
                                            <i class="desactive_password fas fa-times" ref="password_mayus"></i>
                                            <span class="small">&nbsp;Una letra mayúscula</span>
                                        </div>
                                        <div id="password_minus">
                                            <i class="desactive_password fas fa-times" ref="password_minus"></i>
                                            <span class="small">&nbsp;Una letra minúscula</span>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div id="password_numer">
                                            <i class="desactive_password fas fa-times" ref="password_numer"></i>
                                            <span class="small">&nbsp;Un número</span>
                                        </div>
                                        <div id="password_carac">
                                            <i class="desactive_password fas fa-times" ref="password_carac"></i>
                                            <span class="small">&nbsp;Un carácter especial</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-floating mb-4">
                                <input class="form-control" type="password" name="cpassword" id="cpassword" placeholder="Confirmar contraseña" v-model="user.cpassword">
                                <label for="cpassword">Confirmar contraseña</label>
                                <small style="color:#FF6868;" v-for="(e_cpassword, index) in errors.cpassword" :key="index">{{e_cpassword}}</small>
                            </div>
                            <button class="w-100 btn btn-lg btn-primary" @click.prevent="newUser()">Guardar/Modificar</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="responseModal" tabindex="-1" aria-labelledby="responseModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-scrollable">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="responseModalLabel">Resultados</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="row justify-content-center">
                    <div class="col-md-12">
                    <dx-data-grid
                        id="gridContainer"
                        :dataSource="dataSource"
                        :allowColumnReordering="true"
                        :hoverStateEnabled="true"
                        :showColumnLines="true"
                        :showRowLines="true"
                        :showBorders="true"
                        :rowAlternationEnabled="true"
                        :selectedRowKeys="[]"
                        :height="400"
                        :columnAutoWidth="true"
                        :allowColumnResizing="true"  
                    >
                    <dx-toolbar>
                        <dx-item
                        location="after"
                        name="exportButton"
                        ></dx-item>
                        <dx-item
                        location="after"
                        name="columnChooserButton"
                        ></dx-item> 
                    </dx-toolbar>
                        <dx-column-chooser :enabled="true"></dx-column-chooser>
                        <dx-export :enabled="true"></dx-export>
                        <dx-scrolling row-rendering-mode="virtual"></dx-scrolling>
                        <dx-paging :page-size="20"></dx-paging>
                        <dx-pager
                        :visible="true"
                        :allowed-page-sizes="pageSizes"
                        :display-mode="displayMode"
                        :show-page-size-selector="showPageSizeSelector"
                        :show-info="showInfo"
                        :show-navigation-buttons="showNavButtons"
                        ></dx-pager>  
                        <dx-header-filter :visible="true"></dx-header-filter>
                        <dx-filter-row :visible="true"></dx-filter-row>
                        <dx-column-fixing :enabled="true"></dx-column-fixing>
                        <dx-column-chooser
                        :enabled="true"
                        mode="select"
                        :allowSearch="true"
                        >
                        </dx-column-chooser>
                        <dx-selection mode="single"></dx-selection>
                        <dx-state-storing 
                        :enabled="false"
                        type="localStorage"
                        storageKey="storage"
                        >
                        </dx-state-storing>
                        <dx-column 
                        dataField="subdominio"
                        :width="200"
                        :minWidth="80"
                        :fixed="true"
                        caption="Subdominio">
                        </dx-column>
                        <dx-column 
                        dataField="message"
                        :width="200"
                        :minWidth="80"
                        :fixed="true"
                        caption="Mensaje">
                        </dx-column>
                        <dx-group-panel :visible="true"></dx-group-panel>
                    </dx-data-grid>              
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" @click.prevent="aceptAndClean()">Aceptar</button>
            </div>
            </div>
        </div>
    </div>
    <div>
        <dx-load-panel
            :close-on-outside-click="true"
            v-model:visible="isLoadPanelVisible"
            shading-color="rgba(0, 0, 0, 0.2)"
            message="Creando al usuario, espere un momento..."
        ></dx-load-panel>
    </div>
</template>
<script>

import { Modal } from 'bootstrap';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxLookup,
  DxForm,
  DxScrolling,
  DxPager,
  DxExport,
  DxHeaderFilter,
  DxFilterRow,
  DxColumnFixing,
  DxColumnChooser,
  DxSelection,
  DxStateStoring,
  DxGroupPanel,
  DxToolbar,
} from 'devextreme-vue/data-grid';
import { DxLoadPanel } from 'devextreme-vue/load-panel';
import { DxItem } from 'devextreme-vue/form';

export default {
    components: {
        DxDataGrid,
        DxColumn,
        DxPaging,
        DxLookup,
        DxForm,
        DxScrolling,
        DxPager,
        DxExport,
        DxHeaderFilter,
        DxFilterRow,
        DxColumnFixing,
        DxColumnChooser,
        DxSelection,
        DxStateStoring,
        DxGroupPanel,
        DxToolbar,
        DxLoadPanel,
        DxItem
     },
    data() {
        return {
            subdomainAll:false,
            subdomains:[],
            dataSource: [],
            displayModes: [{ text: 'Display Mode \'full\'', value: 'full' }, { text: 'Display Mode \'compact\'', value: 'compact' }],
            displayMode: 'full',
            pageSizes: [5, 10, 'all'],
            showPageSizeSelector: true,
            showInfo: true,
            showNavButtons: true,
            respuestas:[],
            errors:[],
            user:{
                name:"",
                last_name:"",
                email:"",
                password:null,
                cpassword:null,
            },
            isLoadPanelVisible: false
        }
    },
    methods: {
        selectedAll(){
            if(this.subdomainAll==true){
                this.subdomains.map((sub) => {
                    return sub.selected = true;
                });
            }
            else{
                this.subdomains.map((sub) => {
                    return sub.selected = false;
                });
            }
        },
        aceptAndClean(){
            this.modalNewUser.hide();
            this.respuestas =[];
            this.dataSource=[];
            this.errors=[];
            this.user.name="";
            this.user.last_name="";
            this.user.email="";
            this.user.password=null;
            this.user.cpassword=null;
        },
        newUser(){
            let countTrue=0;
            for (let i = 0; i < this.subdomains.length; i++){
                if(this.subdomains[i]['selected']==true){
                    countTrue++;
                }
            }
            if(countTrue==0){
                this.errors = {
                    subdomain:["Selecciona por lo menos un cliente"]
                }
                return;
            }
            this.isLoadPanelVisible= true;
            axios.post('crear-usuarios-capi', {
                name: this.user.name,
                last_name: this.user.last_name,
                email: this.user.email,
                password: this.user.password,
                cpassword: this.user.cpassword,
                subdomains: this.subdomains,
            }).then(response => {
                if(response && response.status == 200){
                    this.respuestas = response.data;
                    this.dataSource = response.data;
                    this.modalNewUser.show();
                }
                else{
                    console.log(response);
                }
                this.isLoadPanelVisible= false;
            }).catch((error)=> {
                if (error.response.status == 422){
                    this.isLoadPanelVisible= false;
                    this.errors = error.response.data.errors;
                }else{
                    console.log(this.errors);
                }
            }); 
        },
        inputKeydown(e){
            const pass = e.key;
            let mayus = new RegExp("^(?=.*[A-Z])");
            let minus = new RegExp("^(?=.*[a-z])");
            let num = new RegExp("^(?=.*[0-9])");
            let special = new RegExp("^(?=.*[-.!%&@#$^*?_~;,:|=¡¿+<>])");
            let upper = this.$refs.password_mayus;
            let lower = this.$refs.password_minus;
            let number = this.$refs.password_numer;
            let specialChar = this.$refs.password_carac; 
            const regExp = [ mayus, minus, num, special ];
            const elements = [ upper, lower, number, specialChar];
            for (let i = 0; i < 4; i++) {                      
                elements[i].classList.remove('active_password','fa-check');
                elements[i].classList.add('desactive_password','fa-times');  
            }    
            let cadena = document.getElementById("ePass").value;
            for (let x = 0; x< cadena.length; x++) {
                let caracter = cadena.charAt(x);
                for (let i = 0; i < 4; i++) {
                    if (pass && regExp[i].test(caracter)) {
                        elements[i].classList.remove('desactive_password', 'fa-times');
                        elements[i].classList.add('active_password', 'fa-check');  
                    }            
                }
            }   
        },
        getList(){
			axios.get('list-subdomain-user').then(response=>{
                this.subdomains = response.data;
            });
        },  
    },
    mounted() {
         this.modalNewUser = new Modal(document.getElementById('responseModal'))
         this.getList();
    },
}
</script>
<style scoped>
  .small {
    font-size: smaller;
  }
  .active_password{
      font-size: smaller;
      color: #33FFA9;
  }
  .desactive_password{
    font-size: smaller;
    color: #C4C4C4;
    transition: all 0.3s;
  }
</style>