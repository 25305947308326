<template>
  <div class="container">
    <div class="d-flex d-flex justify-content-center">
      <div class="col-md-10 p-2 d-flex d-flex justify-content-end align-items-start">
        <div class="">
          <span>Filtrar por Año y Mes:</span>
        </div>
      </div>
      <div class="col-md-2 d-flex d-flex justify-content-center align-items-center">
        <div class="">
          <form action="" method="post">
            <div class="mb-3">
              <dx-select-box
                  :search-enabled="true"
                  :data-source="monthYear"
                  :search-mode="searchModeOption"
                  :search-expr="searchExprOption"
                  :search-timeout="searchTimeoutOption"
                  :min-search-length="minSearchLengthOption"
                  :show-data-before-search="showDataBeforeSearchOption"
                  display-expr="yearmonth"
                  value-expr="yearmonth"
                  v-model="monthyearselected"
                  width="200px"
                  @value-changed="valueChanged"
              ></dx-select-box>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
        <div class="col-md-12">
          <dx-data-grid
            id="gridContainer"
            :dataSource="dataSource"
            :allowColumnReordering="true"
            :hoverStateEnabled="true"
            :showColumnLines="true"
            :showRowLines="true"
            :showBorders="true"
            :rowAlternationEnabled="true"
            :selectedRowKeys="[]"
            :height="600"
            :columnAutoWidth="true"
            :allowColumnResizing="true"  
          >
          <dx-toolbar>
            <DxItem location="before" name="groupPanel" />
            <dx-item
              location="after"
              template="refreshTemplate"
            ></dx-item>
            <dx-item
              location="after"
              name="exportButton"
            ></dx-item>
            <dx-item
              location="after"
              name="columnChooserButton"
            ></dx-item> 
          </dx-toolbar>
          <template #refreshTemplate>
            <dx-button
              icon="plus"
              @click="createBackup()"
            ></dx-button>
          </template>
            <dx-export :enabled="true"></dx-export>
            <dx-scrolling row-rendering-mode="virtual"></dx-scrolling>
            <dx-paging :page-size="20"></dx-paging>
            <dx-pager
              :visible="true"
              :allowed-page-sizes="pageSizes"
              :display-mode="displayMode"
              :show-page-size-selector="showPageSizeSelector"
              :show-info="showInfo"
              :show-navigation-buttons="showNavButtons"
            ></dx-pager>  
            <dx-header-filter :visible="true"></dx-header-filter>
            <dx-filter-row :visible="true"></dx-filter-row>
            <DxGrouping :context-menu-enabled="true" expand-mode="rowClick" />
            <DxGroupPanel :visible="true" empty-panel-text="Use el menú contextual de las columnas de encabezado para agrupar datos" />
            <dx-column-fixing :enabled="true"></dx-column-fixing>
            <dx-column-chooser
              :enabled="true"
              mode="select"
              :allowSearch="true"
            >
            </dx-column-chooser>
            <dx-selection mode="single"></dx-selection>
            <dx-state-storing 
              :enabled="false"
              type="localStorage"
              storageKey="storage"
            >
            </dx-state-storing>
            <dx-column
              dataField="id"
              caption=""
              :showInColumnChooser="false"
              :width="50"
              :allowFiltering="false"
              :allowSorting="false"
              cellTemplate="cellEdit"
              :fixed="true">
            </dx-column>
            <dx-column 
              dataField="file_name"
              :width="200"
              :minWidth="80"
              :fixed="true"
              caption="Archivo">
            </dx-column>
            <dx-column 
              dataField="subdomain_url"
              :width="200"
              :minWidth="80"
              :fixed="true"
              caption="URL">
            </dx-column>
            <dx-column 
              dataField="database_name"
              :width="200"
              :minWidth="80"
              :fixed="true"
              caption="Base de datos">
            </dx-column>
            <dx-column 
              dataField="date_backup"
              :width="200"
              :minWidth="80"
              :fixed="true"
              caption="Respaldo"
              data-type="date"
              format="dd/MM/yyyy HH:mm:ss"
            >
            </dx-column>
            <dx-group-panel :visible="true"></dx-group-panel>
            <template #cellEdit="{ data }"> 
              <div style="text-align: center">
                <a :href="data.data.link" class="dx-icon-download" title="Descargar" style="text-decoration:none;"></a>&nbsp;
                <a v-if="is('Super Administrador|Administrador')" href="#" class="dx-icon-trash" @click="onDeleteBackupFile(data)" title="Eliminar" style="text-decoration:none;"></a>
              </div>
            </template>
          </dx-data-grid>              
        </div>
    </div>
    <dx-popup
      :width="'480px'"
      :height="'auto'"
      :showTitle="true"
      titleTemplate="titleModal"
      :dragEnabled="false"
      :closeOnOutsideClick="true"
      :visible="popupVisibleCreate"
      @hidden="onHidden"
    >
      <template #titleModal>
        <h3>Crear un respaldo</h3>
      </template>
      <div>
        <dx-load-panel
          :close-on-outside-click="true"
          v-model:visible="isLoadPanelVisible"
          shading-color="rgba(0, 0, 0, 0.2)"
          message="Generando respaldo, espere un momento..."
        ></dx-load-panel>
        <div class="row">
          <div class="col-md-12">
            <form action="">
              <div class="form">
                <div class="dx-fieldset">
                  <div class="dx-field">
                    <div class="dx-field-label">Cliente:</div>
                    <div class="dx-field-value">
                        <dx-select-box
                            :search-enabled="true"
                            :data-source="subdomains"
                            :search-mode="searchModeOption"
                            :search-expr="searchExprOption"
                            :search-timeout="searchTimeoutOption"
                            :min-search-length="minSearchLengthOption"
                            :show-data-before-search="showDataBeforeSearchOption"
                            display-expr="name"
                            value-expr="id"
                            v-model="subdomain.id"
                            width="200px"
                        ></dx-select-box>
                      <small style="color:#FF6868;" v-for="(e_subdomain_id, index) in errors.id" :key="index">{{e_subdomain_id}}</small>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="row">
          <div class="modal-footer">
            <dx-button
              type="normal"
              text="Cancelar"
              @click="cancelNewBackup()"
            ></dx-button>
            <dx-button
              icon="save"
              type="default"
              text="Crear"
              @click="newBackup()"
            ></dx-button>
          </div>
        </div>
      </div>
    </dx-popup>
    <dx-popup
      :width="'auto'"
      :height="'auto'"
      :showTitle="true"
      titleTemplate="titleModal"
      :dragEnabled="false"
      :closeOnOutsideClick="true"
      :visible="popupVisibleDelete"
      @hidden="onHiddenDelete"
    >
      <template #titleModal>
        <h3>Eliminar backup</h3>
      </template>
      <div>
        <div class="row">
          <div class="col-md-12">
            <h3>¿Deseas eliminar al backup "{{daily_backup_name}}"?</h3>
            <br>
          </div>
        </div>
        <div class="row">
          <div class="modal-footer">
            <dx-button
              type="normal"
              text="Cancelar"
              @click="cancelDailyBackupDelete()"
            ></dx-button>
            <dx-button
              icon="trash"
              type="danger"
              text="Eliminar"
              @click="deleteDailyBackup()"
            ></dx-button>
          </div>
        </div>
      </div>
    </dx-popup>

    <dx-popup
      :width="'auto'"
      :height="'auto'"
      :showTitle="false"
      titleTemplate="titleModal"
      :dragEnabled="false"
      :closeOnOutsideClick="true"
      :visible="popupVisibleLoading"
      @hidden="onHiddenLoading"
    >
      <template #titleModal>
        <p>Información del sistema</p>
      </template>
      <div>
        <dx-load-panel
            :close-on-outside-click="true"
            v-model:visible="isLoadPanelVisibleGrid"
            shading-color="rgba(0, 0, 0, 0.2)"
            message="Cargando información, espere un momento..."
          ></dx-load-panel>
          <span class="dx-icon-fieldchooser"></span>
      </div>
    </dx-popup>
  </div>
</template>
<script>
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxLookup,
  DxForm,
  DxScrolling,
  DxPager,
  DxExport,
  DxHeaderFilter,
  DxFilterRow,
  DxColumnFixing,
  DxColumnChooser,
  DxSelection,
  DxStateStoring,
  DxGroupPanel,
  DxToolbar,
  DxGrouping,
} from 'devextreme-vue/data-grid';
import {
    DxValidator,
    DxRequiredRule,
    DxCustomRule
} from 'devextreme-vue/validator';
 
import { DxTextArea } from 'devextreme-vue/text-area';
import { DxTextBox } from 'devextreme-vue/text-box';
import { DxItem } from 'devextreme-vue/form';
import { DxPopup } from 'devextreme-vue/popup';
import { DxButton } from 'devextreme-vue/button';
import { DxCheckBox } from 'devextreme-vue';
import { DxSelectBox } from 'devextreme-vue/select-box';
import { DxLoadPanel } from 'devextreme-vue/load-panel';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxLookup,
    DxForm,
    DxItem,
    DxTextArea,
    DxScrolling,
    DxPager,
    DxExport,
    DxHeaderFilter,
    DxFilterRow,
    DxColumnFixing,
    DxColumnChooser,
    DxSelection,
    DxStateStoring,
    DxGroupPanel,
    DxPopup,
    DxButton,
    DxTextBox,
    DxToolbar,
    DxValidator,
    DxRequiredRule,
    DxCustomRule,
    DxCheckBox,
    DxSelectBox,
    DxLoadPanel,
    DxGrouping
  },
  data() {
    return {
      dataSource: [],
      displayModes: [{ text: 'Display Mode \'full\'', value: 'full' }, { text: 'Display Mode \'compact\'', value: 'compact' }],
      displayMode: 'full',
      pageSizes: [5, 10, 'all'],
      showPageSizeSelector: true,
      showInfo: true,
      showNavButtons: true,
      popupVisibleCreate: false,
      popupVisibleDelete:false,
      popupVisibleLoading:false,
      subdomain:{
        id:"",
      },
      monthyearselected:"",
      daily_backup_id:"",
      daily_backup_name:"",
      errors:[],
      subdomains:[],
      monthYear:[],
      searchModeOption: 'contains',
      searchExprOption: 'name',
      searchTimeoutOption: 200,
      minSearchLengthOption: 0,
      showDataBeforeSearchOption: false,
      isLoadPanelVisible: false,
      isLoadPanelVisibleGrid: false,
    };
  },
  methods: {    
    valueChanged(e) {
      this.getList(e.value);
    },
    deleteDailyBackup(){
      axios.delete('respaldos-diarios/'+this.daily_backup_id).then(response => {
          if(response && response.data.statusCode == 200){
              this.cancelDailyBackupDelete();
              this.getList();
          }
          else{
              console.log(response);
          }
      }).catch((error)=> {
          if (error.response.status == 422){
              console.log(error);	
          }else{
              console.log(this.errors);
          }
      }); 
    },
    onHiddenDelete(e){
      this.cancelDailyBackupDelete();
    },
    onHiddenLoading(e){
      this.popupVisibleLoading=false;
      this.isLoadPanelVisibleGrid=false;
    },
    cancelDailyBackupDelete(){
      this.popupVisibleDelete=false;
      this.daily_backup_id = "";
      this.daily_backup_name = "";
    },
    onDeleteBackupFile(value){
      this.popupVisibleDelete=true;
      this.daily_backup_id = value.value;
      this.daily_backup_name = value.data.file_name;
    },
    createBackup(){
      this.cleanTextBox();
      this.showChildModal();
    },
    cancelNewBackup(){
      this.cleanTextBox();
      this.hideChildModal();
      this.errors=[];
    },
    cleanTextBox(){
      this.subdomain.id="";
    },
    newBackup(){
      if(this.subdomain.id==""){
        this.errors.id="No se ha seleccionado un elemento"
      }
      else{
        this.isLoadPanelVisible= true;
        this.errors.id="";
        axios.post('respaldos-diarios', this.subdomain).then(response => {
          if(response && response.data.statusCode == 200){
            this.cleanTextBox();
            this.hideChildModal();
            this.getList();
          }
          else{
            console.log(response);
          }
          this.isLoadPanelVisible= false;
        }).catch((error)=> {
          if (error.response.status == 422){
            this.errors = error.response.data.errors;
          }else{
            console.log(this.errors);
          }
        }); 
      }
    },
    showChildModal(){
      this.popupVisibleCreate =  true;
    },
    hideChildModal(){
      this.popupVisibleCreate =  false;
    },
    onHidden(e) {
      this.cancelNewBackup();
    },
    getList(yearmonth=''){
      this.popupVisibleLoading=true;
      this.isLoadPanelVisibleGrid=true;
		  axios.get('listar-respaldos-diarios/'+yearmonth).then(response=>{
        this.dataSource = response.data.dailyBackups;
        this.subdomains = response.data.subdomains;
        this.monthYear = response.data.monthYear;
        this.popupVisibleLoading=false;
        this.isLoadPanelVisibleGrid=false;
      })
    }
  },
  mounted() {
    this.getList();
  },
};
</script>
<style>
#data-grid-demo {
  min-height: 700px;
}
</style>
